import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';

import {
    LoadingGif
} from "../../images";

const QType16 = ({quesKey, changePrice}) => {

    const onRadioChange = ({target}) => {
        changePrice(target.value)
    }

    return (
        <>
            <div className='question active' >
                {/* <p className="questionName mbs">
                    {quesKey.name}
                </p> */}
                <p className="questionDescr">
                    {quesKey.text}
                </p>
                <div className="questionSelPrice">
                    <div className="questionPriceRadio">
                        <input value={'1.00'} defaultChecked  onChange={onRadioChange}  type="radio" name="price" id="priceRadio-1" />
                        <label
                            htmlFor="priceRadio-1">
                            <b>$1.00</b>
                            <span>USD</span>
                        </label>
                    </div>
                    <div className="questionPriceRadio">
                        <input value={'7.49'} onChange={onRadioChange} type="radio" name="price" id="priceRadio-2" />
                        <label
                            htmlFor="priceRadio-2">
                            <b>$7,49</b>
                            <span>USD</span>
                        </label>
                        <p>Most popular choice</p>
                    </div>
                </div>
                <div className="questionText small tal">
                    <p>
                        {quesKey.descr}
                    </p>
                </div>
            </div>
        </>
    )
}

export default memo(QType16);
